import { default as _91event_id_93Ou07ZnTTXaMeta } from "/tmp/build_bfe39d24/pages/events/[event_id].vue?macro=true";
import { default as experiencesd0cOzEXwRYMeta } from "/tmp/build_bfe39d24/pages/experiences.vue?macro=true";
import { default as indexmBfrvLM0qzMeta } from "/tmp/build_bfe39d24/pages/index.vue?macro=true";
import { default as _91meeting_type_id_938nKOC20yo5Meta } from "/tmp/build_bfe39d24/pages/sessions/[meeting_type_id].vue?macro=true";
import { default as indexiSVWzQagt7Meta } from "/tmp/build_bfe39d24/pages/sessions/index.vue?macro=true";
import { default as events_45slider_45examplegCO1DlZzfgMeta } from "/tmp/build_bfe39d24/pages/widgets/events-slider-example.vue?macro=true";
import { default as events_45sliderOzKgsMa5JAMeta } from "/tmp/build_bfe39d24/pages/widgets/events-slider.vue?macro=true";
export default [
  {
    name: "events-event_id",
    path: "/events/:event_id()",
    component: () => import("/tmp/build_bfe39d24/pages/events/[event_id].vue")
  },
  {
    name: "experiences",
    path: "/experiences",
    meta: experiencesd0cOzEXwRYMeta || {},
    component: () => import("/tmp/build_bfe39d24/pages/experiences.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/tmp/build_bfe39d24/pages/index.vue")
  },
  {
    name: "sessions-meeting_type_id",
    path: "/sessions/:meeting_type_id()",
    component: () => import("/tmp/build_bfe39d24/pages/sessions/[meeting_type_id].vue")
  },
  {
    name: "sessions",
    path: "/sessions",
    meta: indexiSVWzQagt7Meta || {},
    component: () => import("/tmp/build_bfe39d24/pages/sessions/index.vue")
  },
  {
    name: "widgets-events-slider-example",
    path: "/widgets/events-slider-example",
    meta: events_45slider_45examplegCO1DlZzfgMeta || {},
    component: () => import("/tmp/build_bfe39d24/pages/widgets/events-slider-example.vue")
  },
  {
    name: "widget-events-slider",
    path: "/widgets/events-slider",
    meta: events_45sliderOzKgsMa5JAMeta || {},
    component: () => import("/tmp/build_bfe39d24/pages/widgets/events-slider.vue")
  }
]