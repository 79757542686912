import revive_payload_client_4sVQNw7RlN from "/tmp/build_bfe39d24/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/tmp/build_bfe39d24/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/tmp/build_bfe39d24/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/tmp/build_bfe39d24/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/tmp/build_bfe39d24/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/tmp/build_bfe39d24/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/tmp/build_bfe39d24/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_zbacBAXhj0 from "/tmp/build_bfe39d24/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import plugin_vue3_YdLad5Mpq3 from "/tmp/build_bfe39d24/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/tmp/build_bfe39d24/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/tmp/build_bfe39d24/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import primevue_plugin_egKpok8Auk from "/tmp/build_bfe39d24/.nuxt/primevue-plugin.mjs";
import plugin_client_XxbS7tGyhp from "/tmp/build_bfe39d24/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import plugin_Jozdw60ZsE from "/tmp/build_bfe39d24/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_AUP22rrBAc from "/tmp/build_bfe39d24/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import firebase_client_zXNDa0wxFH from "/tmp/build_bfe39d24/plugins/firebase.client.ts";
import primevue_toastservice_d3cpiqGtTZ from "/tmp/build_bfe39d24/plugins/primevue-toastservice.ts";
import vue_final_modal_pML93k5qcp from "/tmp/build_bfe39d24/plugins/vue-final-modal.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_zbacBAXhj0,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  primevue_plugin_egKpok8Auk,
  plugin_client_XxbS7tGyhp,
  plugin_Jozdw60ZsE,
  plugin_AUP22rrBAc,
  firebase_client_zXNDa0wxFH,
  primevue_toastservice_d3cpiqGtTZ,
  vue_final_modal_pML93k5qcp
]